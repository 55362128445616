@import 'normalize.css';


[data-rbd-draggable-id] {
    left: auto !important;
    top: auto !important;
}

* {
    outline: none !important;
}

html {
    font-size: 62.5%;
    font-family: Muli, Roboto, Helvetica Neue, Arial, sans-serif;
    background-color: #262933;
}

body {
    font-size: 14px;
    line-height: 1.4;
}

div {
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
}

html,
body,
#root {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

h1,
.h1 {
    font-size: 24px;
}

h2,
.h2 {
    font-size: 20px;
}

h3,
.h3 {
    font-size: 16px;
}

h4,
.h4 {
    font-size: 15px;
}

h5,
.h5 {
    font-size: 13px;
}

h6,
.h6 {
    font-size: 20px;
}

.ps>.ps__rail-y,
.ps>.ps__rail-x {
    z-index: 99;
}

a[role=button] {
    text-decoration: none;
}

[role="tooltip"] {
    z-index: 999;
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
    ::-webkit-scrollbar {
        width: 12px;
        height: 12px;
        background-color: rgba(0, 0, 0, 0);
    }

    ::-webkit-scrollbar:hover {
        width: 12px;
        height: 12px;
        background-color: rgba(0, 0, 0, 0.06);
    }

    ::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.24);
        border-radius: 20px;
    }

    ::-webkit-scrollbar-thumb:active {
        box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);
        border-radius: 20px;
    }
}

form label {
    z-index: 99;
}

.ExcelTable2007 {
	border: 1px solid rgb(57,64,79);
	border-width: 1px 0px 0px 1px;
	font-size: 11pt;
	font-family: Arial, Helvetica, sans-serif;
	font-weight: 400;
	border-spacing: 0px;
	border-collapse: collapse;
	padding: 10px;
}

.ExcelTable2007 TH {
	background-color: rgb(57,64,79);
	background-repeat: repeat-x; 
	font-weight: bold;
	font-size: 14px;
	border: 1px solid #9EB6CE;
	border-width: 0px 1px 1px 0px;
	height: 17px;
    line-height: 17px;
    color: ghostwhite;
}

.ExcelTable2007 TD {
	border: 0px;
	background-color: white;
	padding: 4px 4px 4px 2px;
	border: 1px solid #9EB6CE;
	border-width: 0px 1px 1px 0px;
}

.ExcelTable2007 TD B {
	border: 0px;
	background-color: white;
    font-weight: bold;
}

.ExcelTable2007 TD.heading {
	background-color: rgb(57,64,79);
	text-align: center;
	border: 1px solid #9EB6CE;
	border-width: 0px 1px 1px 0px;
    font-weight: bold;
    color: ghostwhite;
}

.ExcelTable2007 TH.heading {
	background-color: rgb(57,64,79);
	background-repeat: none;
}

@import 'print.css';

@import 'tables.css';

@import 'react-table.css';

@import 'prism.css';

@import 'tailwind.css';